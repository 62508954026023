import Vue from 'vue'

// import $ from 'jquery'
import axios from "axios";
import vSelect from 'vue-select'
import $ from "jquery";

// Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)

export default {
    components: {},
    data: function () {
        return {
            INFO_TRANSPORTEURS: [],
            crudForm: {
                id: '',
                voyage: '',
                id_suivie_enlevement: this.$route.params.id_suivie_enlevement,
                heure_depart: '',
                bl: '',
                transporteur: '',
                nom_chauffeur: '',
                permis: '',
                num_camion: '',
                num_remorque: '',
                num_chauffeur: '',
                destination: '',
                INFO_TRANSPORTEUR: [],
                details: [
                    {
                        id: '',
                        qte_t: 0,
                        nbre_sac: 0,
                        nbre_big_bag: 0,
                        id_navire_bl: '',
                        LIST_ARTICLE: [],
                        id_dossier_article: '',
                        ENTREPOT: [],
                        id_entrepot: '',
                        be: '',
                        num_ivoice: ''
                    }
                ]
            },
            modalTitle: '',
            is_existe: null,
            is_submit: false,
            filters: {
                voyage: '',
                id_entrepot: '',
                heure_depart: '',
                heure_dechargement: '',
                qte_t: '',
                nbre_sac: '',
                nbre_big_bag: '',
                bill_of_lading: '',
                marque: '',
                bl: '',
                dechargement_entrepot: '',
                be: '',
                num_declaration: '',
                transporteur: '',
                nom_chauffeur: '',
                permis: '',
                num_camion: '',
                num_remorque: '',
                num_chauffeur: '',
                destination: '',
                delivery_time: ''
            },
            btn_submit: false,
            enlevement_details: [],
            numero_voyage: 0,
            ARTICLE: [],
            TRANSPORTEUR: [],
            NOM_CHAUFFEUR: [],
            NUM_CAMION: [],
            NUM_REMORQUE: [],
            NUM_CHAUFFEUR: [],
            DESTINATION: [],
            BILL_OF_LADING: [],
            LIST_TRANSPORTEUR: [],
            LIST_ENTREPOT: [],
            total: {
                total_qte_t: 0,
                total_nbre_sac: 0,
                total_nbre_big_bag: 0,
            },

        }
    },
    computed: {
        Transporteurs() {
            return this.getUniqueValues('transporteur');
        },

        nomChauffeurs() {
            return this.getUniqueValues('nom_chauffeur');
        },

        permis() {
            return this.getUniqueValues('permis');
        },

        NumCamions() {
            return this.getUniqueValues('num_camion');
        },

        NumRemorques() {
            return this.getUniqueValues('num_remorque');
        },
        NumChauffeurs() {
            return this.getUniqueValues('num_chauffeur');
        },
        Destinations() {
            return this.getUniqueValues('destination');
        },
        NumDeclarations() {
            if (!this.enlevement_details) return [];

            const uniqueElement = new Set();
            this.enlevement_details.forEach(item => {
                if (item && item.details) {
                    item.details.forEach(detail => {
                        if (detail && detail.num_ivoice) {
                            uniqueElement.add(detail.num_ivoice);
                        }
                    });
                }
            });

            return Array.from(uniqueElement).map(value => ({
                label: value,
                value: value
            }));
        },
        BL() {
            return this.getUniqueValues('bl');
        },
        dechargement_entrepot() {
            return this.getUniqueValues('dechargement_entrepot');
        },

        BillOfLadding() {
            if (!this.enlevement_details || !this.BILL_OF_LADING) return [];

            const uniqueElement = new Set();
            this.enlevement_details.forEach(item => {
                if (item && item.details) {
                    item.details.forEach(detail => {
                        if (detail && detail.id_navire_bl) {
                            uniqueElement.add(detail.id_navire_bl);
                        }
                    });
                }
            });
            return Array.from(uniqueElement).map(id_navire_bl => {
                const entrepot = this.BILL_OF_LADING.find(e => e.id === id_navire_bl);
                return {
                    label: entrepot ? entrepot.num_bl : 'Inconnu',
                    value: id_navire_bl
                };
            });
        },
        MARQUES() {
            if (!this.enlevement_details || this.enlevement_details.length === 0) return [];

            const uniqueArticles = new Map();

            // Parcourez les enlevement_details
            this.enlevement_details.forEach(item => {
                if (item && item.details) {
                    // Parcourez les détails de chaque item
                    item.details.forEach(detail => {
                        if (detail && detail.LIST_ARTICLE && detail.LIST_ARTICLE.length > 0) {
                            // Parcourez les articles dans LIST_ARTICLE de chaque détail
                            detail.LIST_ARTICLE.forEach(article => {
                                if (article && article.id && article.reference_article) {
                                    // Ajoutez l'article au Map (évite les doublons)
                                    if (!uniqueArticles.has(article.id)) {
                                        uniqueArticles.set(article.id, article);
                                    }
                                }
                            });
                        }
                    });
                }
            });

            // Transformez les articles en options pour le select
            return Array.from(uniqueArticles.values()).map(article => {
                return {
                    label: article.reference_article,
                    value: article.id
                };
            });
        },

        entrepots() {
            if (!this.enlevement_details || !this.LIST_ENTREPOT) return [];

            const uniqueEntrepots = new Set();
            this.enlevement_details.forEach(item => {
                if (item && item.details) {
                    item.details.forEach(detail => {
                        if (detail && detail.id_entrepot) {
                            uniqueEntrepots.add(detail.id_entrepot);
                        }
                    });
                }
            });
            return Array.from(uniqueEntrepots).map(id_entrepot => {
                const entrepot = this.LIST_ENTREPOT.find(e => e.id === id_entrepot);
                return {
                    label: entrepot ? entrepot.nom_entrepot : 'Inconnu',
                    value: id_entrepot
                };
            });
        },

        BE() {
            if (!this.enlevement_details) return [];

            const uniqueElement = new Set();
            this.enlevement_details.forEach(item => {
                if (item && item.details) {
                    item.details.forEach(detail => {
                        if (detail && detail.be) {
                            uniqueElement.add(detail.be);
                        }
                    });
                }
            });

            return Array.from(uniqueElement).map(value => ({
                label: value,
                value: value
            }));
        },

        filteredEnlevementDetails() {
            if (!this.enlevement_details) return [];

            return this.enlevement_details
                .map(item => {
                    if (!item) return null;

                    // Fonction helper pour vérifier les propriétés de manière sûre
                    const safeIncludes = (value, searchStr) => {
                        if (!searchStr) return true;
                        if (value === null || value === undefined) return false;
                        return value.toString().toLowerCase().includes(searchStr.toLowerCase());
                    };

                    // Filtres standards inchangés
                    const matchVoyage = safeIncludes(item.voyage, this.filters.voyage);
                    const matchHeureDepart = safeIncludes(item.heure_depart, this.filters.heure_depart);
                    const matchHeureDechargement = safeIncludes(item.heure_dechargement, this.filters.heure_dechargement);
                    const matchBL = safeIncludes(item.bl, this.filters.bl);
                    const matchdechargement_entrepot = safeIncludes(item.dechargement_entrepot, this.filters.dechargement_entrepot);
                    const matchTransporteur = safeIncludes(item.transporteur, this.filters.transporteur);
                    const matchNomChauffeur = safeIncludes(item.nom_chauffeur, this.filters.nom_chauffeur);
                    const matchPermis = safeIncludes(item.permis, this.filters.permis);
                    const matchNumCamion = safeIncludes(item.num_camion, this.filters.num_camion);
                    const matchNumRemorque = safeIncludes(item.num_remorque, this.filters.num_remorque);
                    const matchNumChauffeur = safeIncludes(item.num_chauffeur, this.filters.num_chauffeur);
                    const matchDestination = safeIncludes(item.destination, this.filters.destination);
                    const matchDeliveryTime = safeIncludes(item.delivery_time, this.filters.delivery_time);

                    const details = item.details || [];

                    // Filtrer les détails en fonction des filtres BOL, Marque et id_entrepot
                    let filteredDetails = details;

                    // Filtre par Bill of Lading (BOL)
                    if (this.filters.bill_of_lading) {
                        filteredDetails = filteredDetails.filter(detail => {
                            const blLabel = detail && detail.id_navire_bl ?
                                this.getBillOfLadingLabel(detail.id_navire_bl) : null;
                            return blLabel === this.filters.bill_of_lading;
                        });
                    }

                    // Filtre par Marque
                    if (this.filters.marque) {
                        filteredDetails = filteredDetails.filter(detail => {
                            const articleLabel = detail && detail.id_dossier_article && detail.LIST_ARTICLE ?
                                this.getArticleLabel(detail.id_dossier_article, detail.LIST_ARTICLE) : null;
                            return articleLabel === this.filters.marque;
                        });
                    }

                    // Filtre par id_entrepot
                    if (this.filters.id_entrepot) {
                        filteredDetails = filteredDetails.filter(detail =>
                            detail && detail.id_entrepot && detail.id_entrepot === this.filters.id_entrepot
                        );
                    }

                    // Vérifier si au moins un détail correspond après filtrage
                    const hasMatchingDetails = filteredDetails.length > 0;

                    // Autres filtres inchangés
                    const matchQteT = !this.filters.qte_t || filteredDetails.some(detail =>
                        detail && safeIncludes(detail.qte_t, this.filters.qte_t)
                    );
                    const matchNbreSac = !this.filters.nbre_sac || filteredDetails.some(detail =>
                        detail && safeIncludes(detail.nbre_sac, this.filters.nbre_sac)
                    );
                    const matchNbreBigBag = !this.filters.nbre_big_bag || filteredDetails.some(detail =>
                        detail && safeIncludes(detail.nbre_big_bag, this.filters.nbre_big_bag)
                    );
                    const matchBE = !this.filters.be || filteredDetails.some(detail =>
                        detail && detail.be && detail.be === this.filters.be
                    );
                    const matchNumDeclaration = !this.filters.num_declaration || filteredDetails.some(detail =>
                        detail && safeIncludes(detail.num_ivoice, this.filters.num_declaration)
                    );

                    // Si tous les filtres correspondent et qu'il y a des détails correspondants
                    if (matchVoyage && matchHeureDepart && matchHeureDechargement &&
                        matchQteT && matchNbreSac && matchNbreBigBag &&
                        matchBL && matchBE && matchNumDeclaration &&
                        matchTransporteur && matchdechargement_entrepot &&
                        matchNomChauffeur && matchPermis && matchNumCamion &&
                        matchNumRemorque && matchNumChauffeur && matchDestination &&
                        matchDeliveryTime && hasMatchingDetails) {

                        // Retourner une copie de l'élément avec les détails filtrés
                        return {
                            ...item,
                            details: filteredDetails, // Seuls les détails filtrés sont inclus
                        };
                    }

                    return null; // Exclure cette ligne si elle ne correspond pas
                })
                .filter(item => item !== null); // Supprimer les lignes exclues
        },
        filteredTotals() {
            const totals = {
                total_qte_t: 0,
                total_nbre_sac: 0,
                total_nbre_big_bag: 0
            };

            if (!this.filteredEnlevementDetails) return totals;

            this.filteredEnlevementDetails.forEach(item => {
                if (!item || !item.details) return;

                item.details.forEach(detail => {
                    if (!detail) return;

                    totals.total_qte_t += parseFloat(detail.qte_t || 0);
                    totals.total_nbre_sac += parseFloat(detail.nbre_sac || 0);
                    totals.total_nbre_big_bag += parseFloat(detail.nbre_big_bag || 0);
                });
            });

            return totals;
        }
    },
    methods: {

        editData (id) {
            axios.post(this.BASE_URL + "/suivieenlevementdetail/getbyid/"+id).then(response => {
                this.crudForm = response.data;
                let formulaire = new FormData();
                formulaire.append('transporteur', this.crudForm.transporteur);
                axios.post(this.BASE_URL + "/transporteur/fetchelements/", formulaire).then(response => {
                    this.INFO_TRANSPORTEURS = response.data;
                })
                this.openModal();
            })
        },
        getUniqueValues(property) {
            if (!this.enlevement_details) return [];

            const uniqueValues = new Set();

            this.enlevement_details.forEach(item => {
                if (item && item[property]) {
                    uniqueValues.add(item[property]);
                }
            });

            return Array.from(uniqueValues).map(value => ({
                label: value,
                value: value
            }));
        },
        pushElementDetails() {
            this.crudForm.details.push({
                id: '',
                qte_t: 0,
                nbre_sac: 0,
                nbre_big_bag: 0,
                id_navire_bl: '',
                LIST_ARTICLE: [],
                id_dossier_article: '',
                ENTREPOT: [],
                id_entrepot: '',
                be: '',
                num_ivoice: ''
            })
        },
        spliceElementDetails(index) {
            this.crudForm.details.splice(index, 1);
        },

        fetchEntrepot() {
            axios.get(this.BASE_URL + "/entrepot/getall/").then(response => {
                this.LIST_ENTREPOT = response.data;
            });
        },

        // Helper pour vérifier si une valeur existe et n'est pas vide
        isValidValue(value) {
            return value !== null && value !== undefined && value !== '';
        },
        getBillOfLadingLabel(id) {
            const bl = this.BILL_OF_LADING.find(item => item.id === id);
            return bl ? bl.num_bl : '';
        },
        getArticleLabel(id, ARTICLES) {

            const article = ARTICLES.find(item => item.id === id);
            return article ? article.reference_article : '';
        },
        getNomEntrepot(id, ENTREPOT) {

            const entrepot = ENTREPOT.find(item => item.id === id);
            return entrepot ? entrepot.nom_entrepot : '';
        },

        calculTotal(index) {

            let total_qte_t = 0, total_nbre_sac = 0, total_nbre_big_bag = 0;
            this.enlevement_details[index].details.forEach(value => {
                total_qte_t += parseFloat(value.qte_t);
                total_nbre_sac += parseFloat(value.nbre_sac);
                total_nbre_big_bag += parseFloat(value.nbre_big_bag)
            });
            this.total = {
                total_qte_t: total_qte_t,
                total_nbre_sac: total_nbre_sac,
                total_nbre_big_bag: total_nbre_big_bag
            };
        },
        fetchTransporteur() {
            axios.get(this.BASE_URL + "/transporteur/getall/").then(response => {
                this.LIST_TRANSPORTEUR = response.data;
            });
        },
        loadInfoTransporteurs() {
            let formulaire = new FormData();
            this.crudForm.num_chauffeur = '';
            this.crudForm.num_remorque = '';
            this.crudForm.num_camion = '';
            this.crudForm.nom_chauffeur = '';
            formulaire.append('transporteur', this.crudForm.transporteur);
            axios.post(this.BASE_URL + "/transporteur/fetchelements/", formulaire).then(response => {
                this.INFO_TRANSPORTEURS = response.data;
            })
        },


        loadInfoTransporteur(index) {
            let formulaire = new FormData();
            this.enlevement_details[index].num_chauffeur = '';
            this.enlevement_details[index].num_remorque = '';
            this.enlevement_details[index].num_camion = '';
            this.enlevement_details[index].nom_chauffeur = '';
            formulaire.append('transporteur', this.enlevement_details[index].transporteur);
            axios.post(this.BASE_URL + "/transporteur/fetchelement/", formulaire).then(response => {
                this.enlevement_details[index].INFO_TRANSPORTEUR = response.data;
            })
        },
        openTimePicker(event) {
            event.target.showPicker();
        },
        addData() {
            this.INFO_TRANSPORTEURS = [];
            this.modalTitle = "Nouveau Suivie Article";
            this.crudForm = {
                id: '',
                voyage: this.numero_voyage,
                id_suivie_enlevement: this.$route.params.id_suivie_enlevement,
                heure_depart: '',
                bl: '',
                transporteur: '',
                nom_chauffeur: '',
                permis: '',
                num_camion: '',
                num_remorque: '',
                num_chauffeur: '',
                destination: '',
                details: [
                    {
                        id: '',
                        qte_t: 0,
                        nbre_sac: 0,
                        nbre_big_bag: 0,
                        id_navire_bl: '',
                        LIST_ARTICLE: [],
                        id_dossier_article: '',
                        ENTREPOT: [],
                        id_entrepot: '',
                        be: '',
                        num_ivoice: ''
                    }
                ]
            }
            this.openModal();
        },
        closeModal() {
            this.$bvModal.hide("myCustomModal");
        },
        openModal() {
            this.$bvModal.show("myCustomModal");
        },

        fetchDetails(id_suivie_enlevement) {
            axios.get(this.BASE_URL + "/suivieenlevementdetail/getbysuivieenlevement/" + id_suivie_enlevement).then(response => {
                if (response.data.element.length > 0) {
                    this.enlevement_details = response.data.element;
                    this.total = response.data.total;
                } else {
                    this.initiDetail();
                }
            });
        },

        fetchArticle() {
            let formulaire = new FormData();
            formulaire.append('id_suivie_enlevement', this.$route.params.id_suivie_enlevement)
            axios.post(this.BASE_URL + "/suivieenlevementdetail/fetcharticle", formulaire).then((response) => {
                this.ARTICLE = response.data;
            })
        },

        getNumeroVoyage(id_suivie_enlevement) {
            axios.get(this.BASE_URL + "/suivieenlevementdetail/getnumerovoyage/" + id_suivie_enlevement).then(response => {
                this.numero_voyage = response.data.numero;
                this.is_existe = response.data.existe;
                this.fetchDetails(this.$route.params.id_suivie_enlevement);
                this.fetchElement(id_suivie_enlevement);
            });
        },

        fetchElement(id_suivie_enlevement) {
            axios.get(this.BASE_URL + "/suivieenlevement/fetchelement/" + id_suivie_enlevement).then(response => {
                console.log(response.data);
                this.BILL_OF_LADING = response.data;
            });
        },

        fetchTranspoteur() {
            var champ = 'transporteur';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/" + champ).then(response => {
                this.TRANSPORTEUR = response.data;
            });
        },
        fetchNomChauffeur() {
            var champ = 'nom_chauffeur';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/" + champ).then(response => {
                this.NOM_CHAUFFEUR = response.data;
            });
        },
        fetchNumCamion() {
            var champ = 'num_camion';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/" + champ).then(response => {
                this.NUM_CAMION = response.data;
            });
        },
        fetchNumRemorque() {
            var champ = 'num_remorque';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/" + champ).then(response => {
                this.NUM_REMORQUE = response.data;
            });
        },
        fetchNumChauffeur() {
            var champ = 'num_chauffeur';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/" + champ).then(response => {
                this.NUM_CHAUFFEUR = response.data;
            });
        },
        fetchDestination() {
            var champ = 'destination';
            axios.get(this.BASE_URL + "/suivieenlevementdetail/selectdistinct/" + champ).then(response => {
                this.DESTINATION = response.data;
            });
        },
        initiDetail() {
            this.enlevement_details = [
                {
                    id: '',
                    id_suivie_enlevement: this.$route.params.id_suivie_enlevement,
                    voyage: this.numero_voyage,
                    heure_depart: '',
                    id_entrepot: '',
                    ENTREPOT: this.LIST_ENTREPOT,
                    heure_dechargement: '',
                    qte_t: '',
                    nbre_sac: '',
                    nbre_big_bag: '',
                    marque: '',
                    id_dossier_article: '',
                    bl: this.numero_voyage,
                    be: '',
                    num_ivoice: '',
                    transporteur: '',
                    bill_of_lading: '',
                    nom_chauffeur: '',
                    permis: '',
                    num_camion: '',
                    num_remorque: '',
                    num_chauffeur: '',
                    destination: '',
                    delivery_time: '',
                    responsable_delivery: '',
                    INFO_TRANSPORTEUR: [],
                    details: [{
                        id: '',
                        id_suivie_enlevement_details: '',
                        id_suivie_article: '',
                        nbre_sac: '',
                        id_entrepot: '',
                        ENTREPOT: this.LIST_ENTREPOT,
                        qte_t: '',
                        nbre_big_bag: '',
                        marque: '',
                        num_ivoice: '',
                        id_dossier_article: '',
                        be: '',
                        bil_of_lading: '',
                        id_navire_bl: '',
                        LIST_ARTICLE: [],
                        LIST_TRANSPORTEUR: [],
                    }]
                }
            ]
        },

        loadElementforBLs(index, id_navirebl) {
            this.crudForm.details[index].id_dossier_article = "";
            let formulaire = new FormData();
            formulaire.append('id_navire_bl', id_navirebl);
            axios.post(this.BASE_URL + "/suivieenlevement/loadelementforbl/", formulaire).then(response => {
                let navire_bl = response.data.navire_bl[0];
                this.crudForm.details[index].LIST_ARTICLE = response.data.article;
                this.crudForm.details[index].num_ivoice = navire_bl.declaration_bl;
            })
        },

        loadElementforBL(index, index_detail, id_navire_bl) {
            let formulaire = new FormData();
            this.enlevement_details[index].details[index_detail].id_dossier_article = '';
            formulaire.append('id_navire_bl', id_navire_bl);
            axios.post(this.BASE_URL + "/suivieenlevement/loadelementforbl/", formulaire).then(response => {
                let navire_bl = response.data.navire_bl[0];
                this.enlevement_details[index].details[index_detail].LIST_ARTICLE = response.data.article;
                this.enlevement_details[index].details[index_detail].num_ivoice = navire_bl.declaration_bl;
            })
        },

        pushElement(index) {
            this.enlevement_details[index].details.push({
                id: '',
                id_suivie_enlevement_details: '',
                id_suivie_article: '',
                nbre_sac: '',
                qte_t: '',
                nbre_big_bag: '',
                marque: '',
                num_ivoice: '',
                id_dossier_article: '',
                bil_of_lading: '',
                be: '',
                id_navire_bl: '',
                LIST_ARTICLE: [],
                LIST_TRANSPORTEUR: [],
                id_entrepot: '',
                ENTREPOT: this.LIST_ENTREPOT,
            })
        },
        spliceElement(index, index_detail) {
            this.enlevement_details[index].details.splice(index_detail, 1);
        },
        pushDetail() {
            // let numero_voyage = parseInt(this.numero_voyage) + (this.enlevement_details.length - 1);
            let numero_voyage = parseInt(this.enlevement_details[this.enlevement_details.length - 1].voyage) + 1;

            this.enlevement_details.push({
                id: '',
                id_suivie_enlevement: this.$route.params.id_suivie_enlevement,
                voyage: numero_voyage,
                id_entrepot: '',
                ENTREPOT: this.LIST_ENTREPOT,
                heure_depart: '',
                heure_dechargement: '',
                qte_t: '',
                nbre_sac: '',
                nbre_big_bag: '',
                marque: '',
                id_dossier_article: '',
                bl: numero_voyage,
                be: '',
                num_ivoice: '',
                transporteur: '',
                bill_of_lading: '',
                nom_chauffeur: '',
                permis: '',
                num_camion: '',
                num_remorque: '',
                num_chauffeur: '',
                destination: '',
                delivery_time: '',
                responsable_delivery: '',
                INFO_TRANSPORTEUR: [],
                details: [{
                    id: '',
                    id_suivie_enlevement_details: '',
                    id_suivie_article: '',
                    nbre_sac: '',
                    id_entrepot: '',
                    ENTREPOT: this.LIST_ENTREPOT,
                    qte_t: '',
                    nbre_big_bag: '',
                    marque: '',
                    num_ivoice: '',
                    id_dossier_article: '',
                    bil_of_lading: '',
                    be: '',
                    id_navire_bl: '',
                    LIST_ARTICLE: [],
                    LIST_TRANSPORTEUR: [],
                }]
            })
        },

        async printElement() {

            const copie_data = JSON.parse(JSON.stringify(this.filteredEnlevementDetails));
            copie_data.forEach(item => {
                delete item.id;
                delete item.ENTREPOT;
                delete item.id_dossier_article;
                delete item.INFO_TRANSPORTEUR;

                item.details.forEach(detail => {
                    delete detail.id_suivie_enlevement_details;
                    delete detail.LIST_ARTICLE;
                    delete detail.LIST_TRANSPORTEUR;
                    delete detail.id_navire_bl;
                })
            })
            const element = JSON.stringify(copie_data);
            let formulaire = new FormData();
            formulaire.append('element', element);
            const response = await axios.post(this.BASE_URL + "/suivieenlevementdetail/printelement", formulaire)
            if (response.data.success == true) {
                window.open(response.data.file_url, '_blank');

            }
        },

        spliceDetail(index, rowData) {
            let that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(async (value) => {
                    if (value == true) {
                        if (rowData.id > 0) {
                            const response = await axios.post(that.BASE_URL + "/suivieenlevementdetail/delete/" + rowData.id);
                            if (response.data.error === "") {
                                this.numero_voyage = parseInt(this.numero_voyage) - 1;
                                this.enlevement_details.splice(index, 1);
                            }
                        } else {
                            this.numero_voyage = parseInt(this.numero_voyage) - 1;
                            this.enlevement_details.splice(index, 1);
                        }

                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        printBL(id) {
            if (id <= 0) {
                Vue.$toast.open({
                    message: "Veuillez enregistrer cette ligne avant d'imprimer le BL",
                    type: 'error',
                    position: 'top-right'
                    // all of other options may go here
                });
            } else {
                // axios.get(this.BASE_URL + "/suivieenlevementdetail/capturebl/"+id).then((response) => {
                //     if (response.data.error == ''){
                window.open(this.BASE_URL + "/suivieenlevementdetail/printbl/" + id, '_blank');
                // }
                // })
            }
        },

        sendWhasApp(id) {
            window.open(this.BASE_URL + "/suivieenlevementdetail/capturebl/" + id, '_blank');

        },

        onSubmits() {
            var that = this;

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        that.btn_submit = true;
                        that.is_submit = true;
                        link = this.crudForm.id <= 0 ? that.BASE_URL + "/suivieenlevementdetail/saveenlevement"
                        : that.BASE_URL + "/suivieenlevementdetail/editenlevement"
                        ;

                        axios.post(link, $("#form_submit").serialize()).then( (response)=> {
                            console.log(response);
                            that.btn_submit = false;
                            that.is_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                that.loadingData();
                                that.closeModal()

                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        onSubmit() {
            var that = this;

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        that.btn_submit = true;
                        that.is_submit = true;
                        link = that.BASE_URL + "/suivieenlevementdetail/addaction";

                        let formualaire = new FormData();
                        formualaire.append('data', JSON.stringify(this.enlevement_details))
                        axios.post(link, formualaire).then(function (response) {
                            console.log(response);
                            that.btn_submit = false;
                            that.is_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                that.loadingData();
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        loadingData() {
            this.getNumeroVoyage(this.$route.params.id_suivie_enlevement);

            this.fetchArticle();
            this.fetchTranspoteur();
            this.fetchNomChauffeur();
            this.fetchNumCamion();
            this.fetchNumRemorque();
            this.fetchNumChauffeur();
            this.fetchDestination();
        }
    },

    watch: {
        // Observer les changements dans les filtres et les données filtrées
        filteredTotals: {
            deep: true,
            handler(newTotals) {
                this.total.total_qte_t = newTotals.total_qte_t;
                this.total.total_nbre_sac = newTotals.total_nbre_sac;
                this.total.total_nbre_big_bag = newTotals.total_nbre_big_bag;
            }
        }
    },
    created: function () {
        this.fetchEntrepot();
        this.fetchTransporteur();

    },
    mounted: function () {
        this.loadingData();
        /*if (parseInt(this.$route.params.id_suivie_enlevement) > 0) {

            this.getDossier(this.$route.params.id_suivie_enlevement);
            this.dossier_bl.id_dossier = this.$route.params.id_suivie_enlevement;
        }*/
    }
}
